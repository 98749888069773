import {
  AlignItems,
  Box,
  Color,
  ColorPreset,
  Glyph,
  H6,
  HoverEffect,
  Icon,
  Interpose,
  Overflow,
  PlainButton,
  Separator,
  Space,
  TypePreset,
  Visibility,
  XYGrid,
  useTheme,
  Breakpoint,
  P,
} from "@gocardless/flux-react";
import {
  SimpleCollapsible,
  SimpleCollapsibleTrigger,
  Transition,
} from "src/components/ui/CollapsiblePanel/SimpleCollapsible";
import { useIsBreakpoint } from "src/hooks/useIsBreakpoint";
import { getLocalTimeZone, today, CalendarDate } from "@internationalized/date";
import { Trans } from "@lingui/macro";
import { useLocalStorage } from "react-use";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import { ToTranslate } from "src/components/i18n";

import {
  SuggestedActionProps,
  useSuggestedActions,
} from "./useSuggestedActions";
import { SuggestedActionTileWrapper } from "./components/SuggestedActionTileWrapper";
import { STORAGE_KEY } from "./types";

const MOBILE_MAX_SUGGESTED_ACTIONS = 2;
const DESKTOP_MAX_SUGGESTED_ACTIONS = 3;

const currentDate = today(getLocalTimeZone());
const beginningOfYear = new CalendarDate(currentDate.year, 0, 0);
const numberOfDays = currentDate.compare(beginningOfYear) + 1;

export const SuggestedActions = () => {
  const { sendEvent } = useSegment();
  const [suggestedActionPreferences, updateSuggestedActionPreferences] =
    useLocalStorage<{ isWrapped?: boolean }>(STORAGE_KEY, {});
  const isDesktop = useIsBreakpoint({ min: Breakpoint.Lg });
  const { theme } = useTheme();
  const { data: suggestedActionsData, isLoading } = useSuggestedActions();

  const rawSuggestedActions = isLoading
    ? Array.from({
        length: isDesktop
          ? DESKTOP_MAX_SUGGESTED_ACTIONS
          : MOBILE_MAX_SUGGESTED_ACTIONS,
      }).map(
        (_, i) =>
          ({
            id: i.toString(),
            shouldShow: true,
          }) as SuggestedActionProps
      )
    : suggestedActionsData;

  const filteredActions = rawSuggestedActions.filter(
    ({ shouldShow }) => shouldShow
  );
  const offsetLogic =
    (DESKTOP_MAX_SUGGESTED_ACTIONS * numberOfDays) % filteredActions.length; // rotate actions in the array, but keep order consistent during a whole day accross reloadings

  const rotatedActions = isLoading
    ? filteredActions
    : [
        ...filteredActions.slice(-offsetLogic),
        ...filteredActions.slice(0, -offsetLogic),
      ];
  const numberOfActions = rotatedActions.length;

  const suggestedActions = rotatedActions.map(
    (props: SuggestedActionProps, index) => (
      <SuggestedActionTileWrapper
        index={index}
        isLoading={isLoading}
        key={props.id}
        {...props}
      />
    )
  );

  const toggleShowMore = (isOpen: boolean) => {
    sendEvent(
      TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_SUGGESTED_ACTION_SHOW_MORE_TOGGLED,
      {
        status: isOpen ? "show" : "hide",
        numberOfActions,
      }
    );
  };

  const toggleSection = (isOpen: boolean) => {
    updateSuggestedActionPreferences({ isWrapped: !isOpen });
    sendEvent(
      TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_SUGGESTED_ACTION_WRAPPER_TOGGLED,
      {
        status: isOpen ? "show" : "hide",
      }
    );
  };

  return (
    <SimpleCollapsible
      defaultOpen={!suggestedActionPreferences?.isWrapped}
      transition={Transition.None}
      onOpenStateChange={toggleSection}
      trigger={(triggerProps) => (
        <PlainButton effect={HoverEffect.TextDecoration} {...triggerProps}>
          <Box layout="flex" width="100%" alignItems={AlignItems.Center}>
            <H6 preset={TypePreset.Heading_05}>
              <Trans>Get more from Gocardless</Trans>
            </H6>
            <Space layout="inline" h={1} />
            <Icon
              name={triggerProps.isOpen ? Glyph.ChevronUp : Glyph.ChevronDown}
              size="16px"
            />
          </Box>
        </PlainButton>
      )}
      collapsedContent={
        <P spaceAbove={0.5} size={2}>
          <ToTranslate>
            Explore our features to get the most from your dashboard
          </ToTranslate>
        </P>
      }
    >
      <Space v={1.5} />
      <Box>
        <Visibility visible={["none", null, null, "block"]}>
          <XYGrid columnGap={2} templateColumns="1fr 1fr 1fr ">
            {suggestedActions.slice(0, DESKTOP_MAX_SUGGESTED_ACTIONS)}
          </XYGrid>

          {numberOfActions > DESKTOP_MAX_SUGGESTED_ACTIONS ? (
            <SimpleCollapsible
              invertTrigger
              onOpenStateChange={toggleShowMore}
              trigger={(triggerProps) => (
                <SimpleCollapsibleTrigger
                  {...triggerProps}
                  css={{ display: "inline-flex", gap: theme.spacing(0.5) }}
                  alignItems={AlignItems.Center}
                  width="auto"
                />
              )}
            >
              <>
                <Space v={2} />
                <XYGrid columnGap={2} rowGap={2} templateColumns="1fr 1fr 1fr">
                  {suggestedActions.slice(
                    DESKTOP_MAX_SUGGESTED_ACTIONS - numberOfActions
                  )}
                </XYGrid>
              </>
            </SimpleCollapsible>
          ) : null}
        </Visibility>
        <Visibility visible={["block", null, null, "none"]}>
          <Box
            borderWidth={1}
            borderColor={ColorPreset.BorderOnLight_04}
            borderRadius={1}
            overflowX={Overflow.Hidden}
            bg={Color.White}
          >
            <Interpose node={<Separator spacing={[[0, 1.5]]} />}>
              {suggestedActions.slice(0, MOBILE_MAX_SUGGESTED_ACTIONS)}
            </Interpose>
            {numberOfActions > MOBILE_MAX_SUGGESTED_ACTIONS ? (
              <SimpleCollapsible
                invertTrigger
                onOpenStateChange={toggleShowMore}
                trigger={(triggerProps) => (
                  <SimpleCollapsibleTrigger
                    {...triggerProps}
                    bg={Color.Brownstone_50}
                    borderColor={Color.Brownstone_200}
                    borderWidth={1}
                    gutterH={1.5}
                    css={{ borderWidth: "1px 0 0 0" }}
                  />
                )}
              >
                <Separator spacing={[[0, 1.5]]} />
                <Interpose node={<Separator spacing={[[0, 1.5]]} />}>
                  {suggestedActions.slice(
                    MOBILE_MAX_SUGGESTED_ACTIONS - numberOfActions
                  )}
                </Interpose>
              </SimpleCollapsible>
            ) : null}
          </Box>
        </Visibility>
      </Box>
    </SimpleCollapsible>
  );
};
