import {
  Dialog,
  P,
  UL,
  LI,
  Color,
  Interpose,
  Space,
  TypePreset,
  Text,
  FontWeight,
} from "@gocardless/flux-react";
import { Route } from "src/common/routing";
import Image from "next/image";
import IBPPaymentDialogSVG from "src/assets/svg/suggested-actions-ibp-payment-dialog.svg";
import { Trans } from "@lingui/macro";

import { DialogBodyWrapper } from "../components/DialogBodyWrapper";
import { withChildrenTarget } from "../components/withChildrenTarget";
import { DialogCTA, DialogCTAProps } from "../components/DialogCTA";

export const TryIBPPaymentsBodyWrapper = () => (
  <DialogBodyWrapper
    bg={Color.Ocean_100}
    image={
      <Image
        src={IBPPaymentDialogSVG}
        alt="one-off-ibp-dialog"
        width={96}
        height={96}
      />
    }
    title={<Trans>Try Instant payments</Trans>}
  >
    <Interpose node={<Space v={1} />}>
      <P preset={TypePreset.Body_01}>
        <Trans>
          Instant Bank Pay is great for when you need confirmation of a one-off
          payment straight away.
        </Trans>
      </P>
      <P>
        <Text weight={FontWeight.Bold}>
          <Trans>Instant payments are great for:</Trans>
        </Text>
      </P>

      <UL>
        <LI>
          <Text preset={TypePreset.Body_01}>
            <Trans>
              Taking a first-time payment so a customer can use your service
              straight away
            </Trans>
          </Text>
        </LI>
        <LI>
          <Text preset={TypePreset.Body_01}>
            <Trans>Charging for an overdue payment</Trans>
          </Text>
        </LI>
        <LI>
          <Text preset={TypePreset.Body_01}>
            <Trans>Taking the deposit for a product or service</Trans>
          </Text>
        </LI>
      </UL>
    </Interpose>
  </DialogBodyWrapper>
);

export const TryIBPPaymentsFooter = ({
  name,
}: Pick<DialogCTAProps, "name">) => (
  <DialogCTA
    route={Route.OneOffPaymentCreate}
    name={name}
    label={<Trans>Try it now</Trans>}
  />
);

export const TryIBPPayments = withChildrenTarget(({ ...props }) => (
  <>
    <Dialog {...props} footer={<TryIBPPaymentsFooter name={props.id} />}>
      <DialogBodyWrapper
        bg={Color.Ocean_100}
        image={
          <Image
            src={IBPPaymentDialogSVG}
            alt="one-off-ibp-dialog"
            width={96}
            height={96}
          />
        }
        title={<Trans>Try Instant payments</Trans>}
      >
        <Interpose node={<Space v={1} />}>
          <P preset={TypePreset.Body_01}>
            <Trans>
              Instant Bank Pay is great for when you need confirmation of a
              one-off payment straight away.
            </Trans>
          </P>
          <P>
            <Text weight={FontWeight.Bold}>
              <Trans>Instant payments are great for:</Trans>
            </Text>
          </P>

          <UL>
            <LI>
              <Text preset={TypePreset.Body_01}>
                <Trans>
                  Taking a first-time payment so a customer can use your service
                  straight away
                </Trans>
              </Text>
            </LI>
            <LI>
              <Text preset={TypePreset.Body_01}>
                <Trans>Charging for an overdue payment</Trans>
              </Text>
            </LI>
            <LI>
              <Text preset={TypePreset.Body_01}>
                <Trans>Taking the deposit for a product or service</Trans>
              </Text>
            </LI>
          </UL>
        </Interpose>
      </DialogBodyWrapper>
    </Dialog>
  </>
));
