import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Box, FontWeight, Text, TypePreset } from "@gocardless/flux-react";
import { i18nMarkTranslate } from "src/components/i18n";

export const AnimatedLoadingText = () => {
  const texts: string[] = [
    i18nMarkTranslate("Creating your account"),
    i18nMarkTranslate("Building your dashboard"),
    i18nMarkTranslate("Verifying your details"),
    i18nMarkTranslate("Completing checks"),
  ];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 5000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <AnimatePresence exitBeforeEnter initial={false}>
        <motion.div
          key={index}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <Text preset={TypePreset.Body_01} weight={FontWeight.SemiBold}>
            {texts[index]}
          </Text>
        </motion.div>
      </AnimatePresence>
    </Box>
  );
};

export default AnimatedLoadingText;
